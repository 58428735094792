import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageGrid from './ImageGrid';

function CollectionSpecific() {
    const { folder } = useParams();
    useEffect(() => {
        document.title = `Collections - ${folder}`;
      }, [folder]);
  


    const [hasError, setHasError] = useState(false);
    const [collectionImages, setCollectionImages] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    const [nextCursor, setNextCursor] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const folderUrl = `https://chrissmithcreative-backend-production.up.railway.app/folder/${folder}`;

    useEffect(() => {
        const fetchCollectionImages = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(folderUrl);
                if (!response.ok) {
                    throw new Error(`Failed to fetch images for folder: ${folder}`);
                }
                const data = await response.json();
                // console.log("API Response Data:", data);
                // console.log("Images are ", data.resources);
                if (data.resources) {
                    setCollectionImages(data.resources);
                    setNextCursor(data.next_cursor || '');
                    setImageCount(data.total_count || data.resources.length);
                } else {
                    setCollectionImages([]);
                    setImageCount(0);
                }
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching collection images:", error);
                setHasError(true);
                setIsLoading(false);
            }
        };

        fetchCollectionImages();
    }, [folder, folderUrl]);

    if (isLoading) {
        return <div className="body-container">Loading...</div>;
    }

    if (hasError) {
        return <div>Error loading images.</div>;
    }

    if (collectionImages.length === 0) {
        return <div>No images found in this collection.</div>;
    }

    return (
      <div>
          <div className="body-container">
            <h1>{folder}</h1>
         </div>
          <div>
              
              <ImageGrid imageData={collectionImages} imageCount={imageCount} nextCursor={nextCursor} url={folderUrl} />
          </div>
        </div>
    );
}

export default CollectionSpecific;
