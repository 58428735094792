
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import $ from 'jquery';

function ZohoForm() {
  const [submitted, setSubmitted] = useState(false);

  // Define the checkMandatory function
  function checkMandatory() {
    const mandatoryFields = ['Last Name', 'Email'];
    let allFieldsValid = true;

    mandatoryFields.forEach((field) => {
      const fieldElement = document.getElementById(field.replace(" ", "_"));
      if (fieldElement && fieldElement.value.trim() === '') {
        alert(`${field} cannot be empty`);
        fieldElement.focus();
        allFieldsValid = false;
      }
    });

    return allFieldsValid;
  }

  // Define the disableError function
  function disableError() {
    const privacyTool = document.getElementById('privacyTool6399304000000478870');
    const privacyErr = document.getElementById('privacyErr6399304000000478870');

    if (privacyTool && privacyTool.checked && privacyErr) {
      privacyErr.style.visibility = 'hidden';
      privacyTool.setAttribute('aria-invalid', 'false');
    }
  }

  function validateEmail() {
    const emailFld = document.querySelectorAll('[ftype=email]');
    let isValid = true;

    emailFld.forEach((field) => {
      const emailVal = field.value.trim();
      if (emailVal.length !== 0) {
        const atpos = emailVal.indexOf('@');
        const dotpos = emailVal.lastIndexOf('.');
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert('Please enter a valid email address.');
          field.focus();
          isValid = false;
        }
      }
    });

    return isValid;
  }

  function privacyAlert() {
    const privacyTool = document.getElementById('privacyTool6399304000000478870');
    const privacyErr = document.getElementById('privacyErr6399304000000478870');
    if (privacyTool && !privacyTool.checked) {
      privacyErr.style.visibility = 'visible';
      privacyTool.setAttribute('aria-invalid', 'true');
      privacyTool.focus();
      return false;
    }
    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const isMandatory = checkMandatory();
    if (isMandatory) {
      const formData = new FormData(document.getElementById('webform6399304000000478870'));
      $.ajax({
        url: 'https://crm.zoho.com/crm/WebToLeadForm',
        type: 'POST',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {
          const splashinfodom = document.getElementById('wf_splash_info');
          if (splashinfodom) {
            splashinfodom.innerText = 'Thanks! Your message has been sent.';
          }

          const splashdom = document.getElementById('wf_splash');
          if (splashdom) {
            splashdom.style.display = '';
            setTimeout(function () {
              splashdom.style.display = 'none';
            }, 5000);
          }

          document.getElementById('webform6399304000000478870').reset();
          document.querySelector('.crmWebToEntityForm .formsubmit').removeAttribute('disabled');
          setSubmitted(true);
        },
        error: function () {
          alert('An error occurred');
        },
      });
    }
  }

  return (
    <div id="crmWebToEntityForm" className="zcwf_lblLeft crmWebToEntityForm" style={{ maxWidth: '600px' }}>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />

      {submitted ? (
        <div className="wf_customMessageBox" style={{ display: 'block' }}>
          <div className="wf_customCircle">
            <div className="wf_customCheckMark"></div>
          </div>
          <span id="wf_splash_info">Thanks! Your message has been sent.</span>
        </div>
      ) : (
        <form id="webform6399304000000478870" name="WebToLeads6399304000000478870" acceptCharset="UTF-8" onSubmit={handleSubmit}>
          <input type="hidden" name="xnQsjsdp" value="859a81577a396ca4916b3d7a187cbe7e0c0b15784cdef0ac3cc2d303dae615cc" />
          <input type="hidden" name="zc_gad" id="zc_gad" value="" />
          <input type="hidden" name="xmIwtLD" value="779fc6f00bce17a93ee7c800ce1b0874f350d45ff6dad953feb786efac71d035201bbcd642e8eb77f2aabeda68a3972f" />
          <input type="hidden" name="actionType" value="TGVhZHM=" />
          <input type="hidden" name="returnURL" value="https://chrissmithcreative.com" />

          {/* <div className="zcwf_title" style={{ maxWidth: '600px', color: 'black', fontFamily: 'Arial' }}>Basic Message Form</div> */}

          <div className="zcwf_row">
            <div className="zcwf_col_lab" style={{ fontSize: '12px', fontFamily: 'Arial' }}>
              <label htmlFor="First_Name">First Name</label>
            </div>
            <div className="zcwf_col_fld">
              <input type="text" id="First_Name" name="First Name" aria-required="false" aria-label="First Name" maxLength="40" />
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_col_lab" style={{ fontSize: '12px', fontFamily: 'Arial' }}>
              <label htmlFor="Last_Name">Last Name<span style={{ color: 'red' }}>*</span></label>
            </div>
            <div className="zcwf_col_fld">
              <input type="text" id="Last_Name" name="Last Name" aria-required="true" aria-label="Last Name" maxLength="80" />
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_col_lab" style={{ fontSize: '12px', fontFamily: 'Arial' }}>
              <label htmlFor="Phone">Phone</label>
            </div>
            <div className="zcwf_col_fld">
              <input type="text" id="Phone" name="Phone" aria-required="false" aria-label="Phone" maxLength="30" />
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_col_lab" style={{ fontSize: '12px', fontFamily: 'Arial' }}>
              <label htmlFor="Email">Email<span style={{ color: 'red' }}>*</span></label>
            </div>
            <div className="zcwf_col_fld">
              <input type="email" id="Email" name="Email" aria-required="true" aria-label="Email" maxLength="100" />
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_col_lab" style={{ fontSize: '12px', fontFamily: 'Arial' }}>
              <label htmlFor="Description">Message</label>
            </div>
            <div className="zcwf_col_fld">
              <textarea id="Description" name="Description" aria-required="false" aria-label="Description"></textarea>
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_privacy">
              <label className="newCustomchkbox-md dIB w100per">
                <input
                  type="checkbox"
                  id="privacyTool6399304000000478870"
                  aria-checked="false"
                  aria-errormessage="privacyErr6399304000000478870"
                  aria-label="privacyTool"
                  onClick={disableError}
                />
                I agree to the{' '}
                <a href="https://chrissmithcreative.com/privacy" title="Privacy Policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                .
              </label>
            </div>
          </div>

          <div className="zcwf_row">
            <div className="zcwf_col_fld">
              <input
                type="submit"
                id="formsubmit"
                className="formsubmit zcwf_button"
                value="Submit"
                aria-label="Submit"
                title="Submit"
              />
              <input
                type="reset"
                className="zcwf_button"
                value="Reset"
                aria-label="Reset"
                title="Reset"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default ZohoForm;
