import React, { useEffect }  from 'react';
import me from './images/me.png';
import grandma_betty from './images/grandma_betty_web.jpg';
import ZohoForm from './ZohoForm'

function About({collections}) {

  
    useEffect(() => {
      document.title = "About Me";
    }, []);


    return (
      <div className="body-container">
         <h1>About Me</h1>
         <img src={me} alt="Chris Smith Creative Logo" width="250px" />
         <p>Hi, I'm Chris.</p>
         <p>As an artist who finds inspiration in the tranquility of nature, 
         I love how paintings have the power to transport you to different places and moments. Art allows you to experience the beauty of a scene as if you were there. </p>
         <h2>My Artistic Style, and Vision</h2> 
         Watercolor is my preferred medium, as it allows me to embrace the natural element 
         of water and relinquish some control in the artistic process. As someone who is very left-brained and
         prone to over-processing, watercolor teaches me to let go and collaborate with nature, rather than fighting against it. This approach also brings a sense of spontaneity and freedom to my work.
         <p>My work is an ongoing exploration of how composition and color can evoke powerful emotions and memories.</p>
         <h2>A Bit About Me</h2>
         <p>Outside of my art, I work as a digital analytics specialist where I help communications teams understand the effectiveness of their campaigns by making sense of digital data. Data visualization is where I currently put my creativity to use, but I have had experience leading digital projects through the web and social media in a campaign manager role.</p>
         <p>In fact, I built this website (front end and backend) with my web development experience. I used React for the front-end development, creating a dynamic and responsive user interface, and Express for the back-end, handling server-side logic and API integrations. I store and retrieve my image assets on Cloudinary, a cloud-based image and video management service that allows me to efficiently manage media storage, transformation, and delivery. Additionally, I built custom interaction events to track user engagements in Google Analytics with my art lightboxes, providing insights into how visitors interact with my work. I don’t have ecommerce yet, but maybe that will be something I add in the future.</p>
         <h2> The Story Behind My Signature</h2>
        My full name is Christopher Hunter Smith, and for the longest time, I had a generic "CHS" signature. Wanting something more creative and evocative of nature for my art, I experimented with various designs. Eventually, one combination emerged that resembled the shape of a canoe, and the rest is history. The prominence of my middle initial, “H,” (which i designed to intentionally resemble oars) was a happy accident that now feels like a fortunate twist of fate.
        <h3>What does the “H” stand for?</h3>
        I initially chose watercolor because it seemed more affordable and approachable than acrylic as a beginner. Before that, I mostly sketched, with no guidance on where to go next. It wasn’t until later that I realized watercolor runs in my family. I’ve always cherished a photo of my grandma Betty on my shelf, thinking it was just a lovely picture in a beautiful setting. Recently, I discovered she was actually sketching a scene for a watercolor piece in that photo! To top it off, my middle name, Hunter, was given to honor my grandmother’s maiden name, Kathleen Hunter. To pay tribute to my grandmother and the artistic talents she passed down to me, I emphasize the "H" in my "CHS" signature. It’s a small but meaningful way to keep her legacy alive in my work, recognizing the connection we share through watercolor.
        <figure>
        <img src={grandma_betty} alt="Grandma Betty" />
        <figcaption>Grandma Betty sketching a scene for one of her watercolor pieces. The "H" in my signature representing my middle name "Hunter" comes from her maiden name Kathleen Hunter.</figcaption>
      </figure>
         <h2>Let's Connect</h2>
         <p>Feel free to connect with me on my <a href="https://www.instagram.com/smithch610">Instagram</a> page. If you are interested in any of my available pieces or have any questions, please reach out through the form below</p>
        <div>
        <ZohoForm />
        </div>
      </div>
    );
  }
  
  export default About;
  