import React, { useEffect } from 'react';
import ImageCarousel from './ImageCarousel';
import LatestWork from './LatestWork';

const slides = [
  {
    url: 'https://res.cloudinary.com/dn0c3esmd/image/upload/fl_preserve_transparency/v1723922734/Spring_North_Cascades_mgqcre.jpg?_s=public-apps',
    description: 'Spring in the North Cascades: Three Fingers and Whitehorse',
    collection: 'Washington',
  }
  // {
  //   url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Amsterdam_Canals_-_July_2006.jpg/1920px-Amsterdam_Canals_-_July_2006.jpg',
  //   description: 'Amsterdam Canals in July 2006.',
  //   collection: 'Test 2'
  // }
];



function Home({excludedFolders, excludedImages}) {

  useEffect(() => {
    document.title = "Chris Smith Creative";
  }, []);

  // console.log("excluded folders is ", excludedFolders)
  // console.log("excluded images is ", excludedImages)
  return (

    <div>
            <ImageCarousel slides={slides} interval={20000} />
            {/* <div className="body-container">
              <h1>Collections</h1>
            </div>
            <CollectionThumbnail/> */}
            <LatestWork excludedFolders={excludedFolders} excludedImages={excludedImages}/>
     </div>
  );
}

export default Home;
