/* eslint-disable no-unused-vars */

import React, { useEffect }  from 'react';
import { Link, useLocation } from 'react-router-dom';

function Privacy() {

  
    useEffect(() => {
      document.title = "Privacy Policy";
    }, []);


    return (
      <div className="body-container">
        <h1>Privacy Policy</h1>

        <p><strong>Last Updated:</strong> 8/22/24</p>

        <h2>1. Introduction</h2>
        <p>Welcome to <strong>chrissmithcreative.com</strong>. This privacy policy outlines how I collect, use, and protect your personal information when you visit my website. I am committed to ensuring your privacy is protected. Any information you provide will only be used in accordance with this privacy policy.</p>

        <h2>2. Information I Collect</h2>
        <p>I may collect the following information:</p>
        <ul>
            <li><strong>Personal Information:</strong> When you fill out a form on my website, I may collect your name, email address, and other contact details. This information is stored securely in my Customer Relationship Management (CRM) system.</li>
            <li><strong>Behavioral Data:</strong> I use Google Analytics to track and analyze how visitors interact with my website. This includes information such as pages visited, time spent on the site, and other relevant behavioral metrics. This data is collected in aggregate and does not identify individual users, nor is it linked to any personal information in my CRM.</li>
        </ul>

        <h2>3. How I Use Your Information</h2>
        <p>The information I collect is used for the following purposes:</p>
        <ul>
            <li><strong>CRM Management:</strong> To store and manage your contact information, which helps me respond to your inquiries.</li>
            <li><strong>Website Analytics:</strong> To understand how visitors use my website, enabling me to improve user experience and optimize content.</li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p>I do not sell, trade, or rent your personal information to third parties.</p>

        <h2>5. Security</h2>
        <p>I am committed to ensuring that your information is secure. I implement a variety of security measures to maintain the safety of your personal information stored in my CRM. However, please be aware that no method of transmitting data over the internet or storing electronic data is completely secure.</p>

        <h2>6. Google Analytics</h2>
        <p>My website uses Google Analytics to help me analyze how visitors use the site. Google Analytics uses cookies and similar technologies to collect and analyze information about the use of my site and report on activities and trends. This data helps me improve the website and tailor content to my visitors' interests. The data collected through Google Analytics is anonymized and is not linked to any personal information in my CRM.</p>

        <p>You can opt-out of Google Analytics by installing the Google Analytics opt-out browser add-on, available <a href="https://tools.google.com/dlpage/gaoptout">here</a>.</p>

        <h2>7. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
            <li>Access the personal data I hold about you in my CRM</li>
            <li>Request corrections to any inaccurate or incomplete CRM information</li>
            <li>Request the deletion of your personal data stored in my CRM</li>
            <li>Opt-out of receiving any communications from me</li>
        </ul>
        <p>Please note that since the data collected by Google Analytics is anonymized and not linked to any personal information, it cannot be connected to a specific individual for deletion or modification.</p>

        <p>If you would like to exercise any of these rights regarding your CRM data, please contact me using the information provided below.</p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>I may update this privacy policy from time to time. I will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>

        <h2>9. Contact Me</h2>
        <p>If you have any questions or concerns about this privacy policy, please contact me at:</p>

        <p><strong>Christopher Hunter-Smith</strong>
        </p>
      </div>
    );
  }
  
  export default Privacy;
  