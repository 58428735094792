/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageGrid from './ImageGrid'

function LatestWork({excludedFolders, excludedImages}) {
   // const { folder } = useParams();

   useEffect(() => {
    document.title = "All Work";
  }, []);

const [hasError, setHasError] = useState(false);
const [allImages, setAllImages] = useState([]);
const [imageCount, setImageCount] = useState(0);
const [nextCursor, setNextCursor] = useState('');
const [isLoading, setIsLoading] = useState(true);

const imageUrl = `https://chrissmithcreative-backend-production.up.railway.app/allimages`;

useEffect(() => {
    const fetchAllImages = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch images`);
            }
            const data = await response.json();

            if (data.images) {
                // console.log('data images ', data.images)
                const filterByImages = data.images.filter(item => !excludedImages.includes(item.asset_id));
                // console.log('filter by images ', filterByImages)
                const filterByFolders = filterByImages.filter(item => !excludedFolders.includes(item.asset_folder))
                // console.log("filtered by images and folders", filterByFolders)
                setAllImages(filterByFolders);
                setNextCursor(data.nextCursor || '');
                setImageCount(data.totalCount || data.images.length);
            } else {
                setAllImages([]);
                setImageCount(0);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching collection images:", error);
            setHasError(true);
            setIsLoading(false);
        }
    };

    fetchAllImages();
}, [imageUrl, excludedImages, excludedFolders]);

const latesWorkImages = allImages.slice(0,8)
// console.log("all images is ", latesWorkImages)

if (isLoading) {
    return <div className="body-container">Loading...</div>;
}

if (hasError) {
    return <div className="body-container">Error loading images.</div>;
}

if (allImages.length === 0) {
    return <div className="body-container">No images found.</div>;
}

return (
  <div>
      <div className="body-container">
        <h1>Latest Work</h1>
     </div>
      <div>
          
          <ImageGrid imageData={latesWorkImages} imageCount={imageCount} url={imageUrl} isAllWorkPage={true}/>
      </div>
    </div>
);
}

export default LatestWork;