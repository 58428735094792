import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CollectionThumbnail() {
  const [hasError, setHasError] = useState(false);
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const tagURL = `https://chrissmithcreative-backend-production.up.railway.app/tag/image/collection_thumbnail`;

  useEffect(() => {
    fetch(tagURL)
      .then(response => response.json())
      .then((data) => {
        setCollections(data.images);
        setIsLoading(false);
        // console.log("API response data: ", data); // Log the full response
      })
      .catch((error) => {
        console.log("Fetch error: ", error);
        setIsLoading(false);
        setHasError(true);
      });
  }, [tagURL]);

  // console.log("Collections JSON object: ", collections);


  if (isLoading) {
    return <div className="body-container">Loading...</div>;
  }

  if (hasError) {
    return <div>Error loading collections.</div>;
  }

  if (collections.length === 0) {
    return <div>No images available.</div>;
  }

  
  return (
    <div className="collections-grid">
      {collections.map((image, index) => {
        return (
   
          <Link to={`/collections/${image.asset_folder}`} key={image.public_id || image.asset_folder}>
          <div className="collections-item" key={index}>
            <img
              src={image.url}
              alt={image.description || 'Collection image'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                display: 'block',
              }}
            />
            <p>{image.asset_folder}</p>
          </div>
          </Link>
        );
      })}
    </div>
  );
}

export default CollectionThumbnail;