import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo/logo_green_blue.png';

function Header({ collections }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();



  useEffect(() => {
    // Close the menu when the location changes
    setMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="container-header">
      <div className="logo"> 
        <Link to="/" onClick={() => setMenuOpen(false)}><img src={logo} alt="Chris Smith Creative Logo" width="150px" /></Link>
        
      </div>
      <div className={`menu-toggle ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        ☰ {/* Hamburger menu icon */}
      </div>
      <div className={`main-nav ${menuOpen ? 'menu-open' : ''}`}>
        <nav>
          <ul className="menu">
            <li className="menu-item">
              <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
            </li>
            <li className="menu-item">
              <Link to="/about" onClick={() => setMenuOpen(false)}>About Me</Link>
            </li>
            <li className={`menu-item dropdown ${location.pathname.includes('/collections') ? 'active' : ''}`}>
              <Link to="/collections" onClick={toggleMenu}>Collections</Link>
              <ul className="dropdown-menu">
                {collections.map((item, index) => {
                  const isActive = location.pathname === `/collections/${item.name}`;
                  return (
                    <li key={index} className={isActive ? 'active' : ''}>
                      <Link
                        to={`/collections/${item.name}`}
                        onClick={() => setMenuOpen(false)}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className="menu-item">
              <Link to="/all-work" onClick={() => setMenuOpen(false)}>All Work</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
