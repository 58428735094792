import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

function ImageGrid({ imageData = [], imageCount = 0, nextCursor = '', url, isAllWorkPage }) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState(imageData);
  const [cursor, setCursor] = useState(nextCursor);
  const [zoom, setZoom] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  var dataLayer = window.dataLayer = window.dataLayer || [];

  const itemsPerPage = 20; // Set the number of items per page
  const isMobileScreen = () => window.innerWidth <= 1100;

  const totalPages = Math.ceil(imageCount / itemsPerPage);



  const fetchMoreImages = useCallback(async () => {
    if (cursor) {
      try {
        const response = await fetch(`${url}/${cursor}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        const fetchedImages = data.resources || data.images || [];
        if (fetchedImages.length > 0) {
          setImages((prevImages) => [...prevImages, ...fetchedImages]);
          setCursor(data.next_cursor || data.nextCursor);
        }
      } catch (error) {
        console.error("Error fetching images: ", error);
      }
    }
  }, [cursor, url]);

  useEffect(() => {
    if (cursor) {
      fetchMoreImages();
    }
  }, [fetchMoreImages, cursor]);

  const openLightbox = (image) => {
    if (!isMobileScreen() && window.innerWidth > 1100) {
      setSelectedImage(image);
      setIsLightboxOpen(true);
      setZoom(1);
      setPosition({ x: 0, y: 0 });
      console.log("image is ", image)
      dataLayer.push({
        'event': 'image_view', 
        'image_folder': image.asset_folder,
        'image_name': image.metadata.title,
        'image_date': image.created_at,
        'image_id': image.asset_id, 
        'image_availability': image.metadata.availability[0],
        'image_price': image.metadata.price
      });
    }
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setSelectedImage(null);
  };


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const goToNextPage = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      await fetchMoreImages(); // Ensure images are loaded before scrolling
       scrollToTop(); // Added this line
    }
  };
  

  const goToPreviousPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      await fetchMoreImages(); // Ensure images are loaded before scrolling
      scrollToTop(); // Added this line
    }
  };



  const handleWheel = (event) => {
    setZoom((prevZoom) => Math.min(Math.max(prevZoom + event.deltaY * -0.01, 1), 2));
  };

  const handleMouseDown = (event) => {
    setDragging(true);
    setStartPos({ x: event.clientX - position.x, y: event.clientY - position.y });
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      setPosition({
        x: event.clientX - startPos.x,
        y: event.clientY - startPos.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const PaginationControls = () => (
    <div className="pagination-controls">
      <button onClick={goToPreviousPage} disabled={currentPage === 1}>
        Previous
      </button>
      <span>Page {currentPage} of {totalPages}</span>
      <button onClick={goToNextPage} disabled={currentPage === totalPages && !cursor}>
        Next
      </button>
    </div>
  );

  const currentItems = images.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
        {nextCursor && (
        <div className="body-container">
          <PaginationControls />
        </div>
      )}
      <div className="images-container">
        {currentItems.map((image, index) => (
          <div className="image-item" key={index}>
            <img
              name={image.filename}
              onClick={() => openLightbox(image)}
              src={image.url}
              alt={image.description || `Image ${index + 1}`}
              style={{ width: '100%', height: 'auto', display: 'block', objectFit: 'cover' }}
            />
            {!isMobileScreen() && (
              <div>
                <p>{image?.metadata?.title ?? 'Untitled'}</p>
                {isAllWorkPage && (
                  <p>
                    Collection: <Link to={`/collections/${image.asset_folder}`}>{image.asset_folder}</Link>
                  </p>
                )}
              </div>
            )}
            {isMobileScreen() && (
              <div className="image-metadata">
                <p><strong>{image?.metadata?.title ?? 'Untitled'}</strong></p>
                <p>{image?.metadata?.description ?? 'No description available'}</p>
                <p><strong>Dimensions: </strong>
                  {image?.metadata?.width__inches_ ?? 'Unknown'} inches tall x{' '}
                  {image?.metadata?.height__inches_ ?? 'Unknown'} inches wide
                </p>
                <p><strong>Medium: </strong> {image?.metadata?.medium ?? 'Unknown'}</p>
                <p><strong>Availability: </strong>
                {image?.metadata?.availability ?? 'Unknown' }
              </p>
   
                {isAllWorkPage && (
                  <p><strong>Collection: </strong>
                     <Link to={`/collections/${image.asset_folder}`}>{image.asset_folder}</Link>
                  </p>
                )}
              </div>
            )}
          </div>
        ))}

{isLightboxOpen && (
  <div
    className="lightbox-background"
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    onWheel={handleWheel}
  >
    <div className="lightbox-content">
      <div className="lightbox-image-container" style={{ overflow: 'hidden', cursor: dragging ? 'grabbing' : 'grab' }}>
        <img
          className="lightbox-image"
          src={selectedImage?.url ?? 'default-image-url'} // Provide a default URL if `url` is missing
          alt={selectedImage?.description ?? 'No description available'} // Provide a default description
          style={{
            transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
            transition: dragging ? 'none' : 'transform 0.3s ease',
          }}
        />
      </div>

      <div className="caption">
        <p><strong>{selectedImage?.metadata?.title ?? 'Untitled'}</strong></p>
        <p>{selectedImage?.metadata?.description ?? 'No description available'}</p>
        <p><strong>Dimensions: </strong>
          {selectedImage?.metadata?.width__inches_ ?? 'Unknown'} inches tall x{' '}
          {selectedImage?.metadata?.height__inches_ ?? 'Unknown'} inches wide
        </p>
        <p><strong>Medium: </strong>{selectedImage?.metadata?.medium ?? 'Unknown'}</p>
        <p><strong>Availability: </strong>
          {selectedImage.metadata.availability }
        </p>
      </div>
      
      <span className="close" onClick={closeLightbox}>  &times;
      </span>
    </div>
  </div>
)}
      </div>
      {nextCursor && (
        <div className="body-container">
          <PaginationControls />
        </div>
      )}
    </div>
  );
}

export default ImageGrid;
