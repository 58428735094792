import React, { useEffect }  from 'react';
import CollectionThumbnail from './CollectionsThumbnail';


function Collections({collections}) {
    useEffect(() => {
      document.title = "Collections";
    }, []);

    return (
      <div>
        <div className="body-container">
         <h1>Collections</h1>
        <p>Information about my collections</p>
        </div>
        
        <CollectionThumbnail/>
      </div>
    );
  }
  
  export default Collections;
  