import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

const ImageCarousel = ({ slides, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [heroBanners, setHeroBanners] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const slideInterval = useRef(null);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  }, [slides.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    slideInterval.current = setInterval(nextSlide, interval);

    return () => {
      clearInterval(slideInterval.current);
    };
  }, [interval, nextSlide]);

  const heroURL = `https://chrissmithcreative-backend-production.up.railway.app/tag/image/hero`;

  useEffect(() => {
    fetch(heroURL)
      .then(response => response.json())
      .then((data) => {
        setHeroBanners(data.images);
        setIsLoading(false);
        // console.log("API response data: ", data); // Log the full response
      })
      .catch((error) => {
        console.log("Fetch error: ", error);
        setIsLoading(false);
        setHasError(true);
      });
  }, [heroURL]);

  // console.log("Hero banners are ", heroBanners);


  if (isLoading) {
    return <div className="body-container">Loading...</div>;
  }

  if (hasError) {
    return <div>Error loading collections.</div>;
  }

  if (heroBanners.length === 0) {
    return <div>No images available.</div>;
  }




  return (
    <div className="carousel-container">
      <button className="prev-button" onClick={prevSlide}>
        &#10094; {/* Left arrow */}
      </button>
      <div className="carousel-slide">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            style={{
              opacity: index === currentIndex ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <img
              src={slide.url}
              alt={slide.description}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                display: 'block',
              }}
            />
          </div>
        ))}
      </div>
      <button className="next-button" onClick={nextSlide}>
        &#10095; {/* Right arrow */}
      </button>

      <div className="carousel-description">
      <p>{slides[currentIndex].description} | <Link to={`/collections/${slides[currentIndex].collection}`}>{slides[currentIndex].collection} Collection</Link></p>
      </div>
    </div>
  );
};

export default ImageCarousel;