/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useMemo } from 'react';
import { Route, Routes} from 'react-router-dom';
import Home from './Home';
import Header from './Header';
import Collections from './Collections';
import CollectionSpecific from './CollectionSpecific';
import About from './About';
import AllWork from './AllWork'
import Footer from './Footer'
import Privacy from './Privacy'
// import AllWork from './Cloudinary-All-Work';

function App() {

  const [hasError, setHasError] = useState(false);
  const [collections, setCollections] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  
  //Code to get folders/collections
  const collectionsURL = 'https://chrissmithcreative-backend-production.up.railway.app/allfolders'
 
  // Memoize excluded folders and images
  const excludedFolders = useMemo(() => ['Hidden', 'cld_system_files'], []);
  const excludedImages = useMemo(() => ['1b61fd32715b69e374f36f9771152e99'], []);

  useEffect(() => {
  
  
    fetch(collectionsURL)
      .then(response => response.json())
      .then((data) => {
        const filteredData = data.filter(item => !excludedFolders.includes(item.name));
        setCollections(filteredData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setHasError(true);
      });
  }, [collectionsURL, excludedFolders]);

  // console.log("Folders JSON object ", collections)
  // console.log("Is loading: ", isLoading)

  return (
    <>
      <Header collections={collections}/>
      <Routes>
        <Route path = "/"  element={<Home collections={collections} excludedFolders={excludedFolders} excludedImages={excludedImages} />}/>
        <Route path = "/collections"  element={<Collections collections={collections}/>}/>
        <Route path = "/collections/:folder"  element={<CollectionSpecific collections={collections} />}/>
        <Route path = "/about"  element={<About collections={collections} />}/>
        <Route path = "/all-work"  element={<AllWork collections={collections} excludedFolders={excludedFolders} excludedImages={excludedImages}/>}/>
        <Route path = "/privacy"  element={<Privacy />}/>
      
      
        {/* <Route path = "/all-work"  element={<AllWork/>}/> */}
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
