/* eslint-disable no-unused-vars */

import React from 'react';
import { FaInstagram } from 'react-icons/fa'; //FaEnvelope  
import { Link, useLocation } from 'react-router-dom';

// <a href="mailto:yourname@example.com">
// <FaEnvelope />
// </a>
export default function Footer() {
    return (
        <div className="footer-container">
            <hr className="footer-divider" />
            <div className="footer-content">
                <span>Copyright © 2024 Chris Smith Creative - All Rights Reserved  |  <Link to="/privacy">Privacy Policy</Link></span>
                <div className="footer-icons">
                    <a href="https://www.instagram.com/smithch610" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                    
              
                </div>
            </div>
        </div>
    );
}